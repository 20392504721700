export const formatPhoneNumber = (value: string) => {
    const number = value.replace(/\D/g, '');
    
    if (!value.startsWith('+7')) {
      return '+7 ';
    }
  
    let formattedNumber = '+7 ';
    if (number.length > 1) {
      const rest = number.slice(1);
      if (rest.length > 3) {
        formattedNumber += `(${rest.slice(0, 3)})`;
      } else {
        formattedNumber += `(${rest}`;
      }
      
      if (rest.length > 3) {
        formattedNumber += ` ${rest.slice(3, 6)}`;
      }
      
      if (rest.length > 6) {
        formattedNumber += `-${rest.slice(6, 8)}`;
      }
      
      if (rest.length > 8) {
        formattedNumber += `-${rest.slice(8, 10)}`;
      }
    }
    
    return formattedNumber;
  };
  
  export const normalizePhone = (phoneNumber: string) => {
    return '8' + phoneNumber.replace(/\D/g, '').slice(1);
  };
  
  export const formatPhoneForDisplay = (phoneNumber: string) => {
    const number = phoneNumber.slice(1);
    return formatPhoneNumber('+7' + number);
  };