// src/utils/dateHandler.ts

export class DateHandler {
    // Нормализует дату к началу дня в UTC
    static normalizeToUTCDay(date: Date): Date {
      const normalized = new Date(date);
      normalized.setUTCHours(0, 0, 0, 0);
      return normalized;
    }
  
    // Нормализует дату к концу дня в UTC
    static normalizeToUTCEndDay(date: Date): Date {
      const normalized = new Date(date);
      normalized.setUTCHours(23, 59, 59, 999);
      return normalized;
    }
  
    // Конвертирует локальную дату в UTC для отправки на сервер
    static localToUTC(localDate: Date): Date {
      return new Date(
        Date.UTC(
          localDate.getFullYear(),
          localDate.getMonth(),
          localDate.getDate(),
          0, 0, 0, 0
        )
      );
    }
  
    // Конвертирует UTC дату с сервера в локальную для отображения
    static UTCToLocal(utcDate: string | Date): Date {
      const date = new Date(utcDate);
      return new Date(
        date.getUTCFullYear(),
        date.getUTCMonth(),
        date.getUTCDate(),
        0, 0, 0, 0
      );
    }
  }