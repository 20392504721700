import React, { useState, useEffect } from 'react';
import '../styles/RoomManagementScreen.scss';

interface Room {
  _id?: string;
  name: string;
  groupPrice: number;
  individualPrice: number;
  photos?: string[];
  description?: string;
}

const RoomManagementScreen: React.FC = () => {
  const [rooms, setRooms] = useState<Room[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [editingRoom, setEditingRoom] = useState<Room | null>(null);
  const [previewUrls, setPreviewUrls] = useState<string[]>(['', '']);

  const initialRoomState = {
    name: '',
    groupPrice: 0,
    individualPrice: 0,
    photos: ['', ''],
    description: ''
  };

  useEffect(() => {
    fetchRooms();
  }, []);

  useEffect(() => {
    if (editingRoom) {
      setPreviewUrls(editingRoom.photos || ['', '']);
    } else {
      setPreviewUrls(['', '']);
    }
  }, [editingRoom]);

  const fetchRooms = async () => {
    try {
      const response = await fetch('/api/rooms');
      const data = await response.json();
      if (data.success) {
        setRooms(data.rooms);
      }
    } catch (error) {
      console.error('Error fetching rooms:', error);
    }
  };

  const handleEditClick = (room: Room) => {
    // Убедимся, что photos существует
    const roomWithPhotos = {
      ...room,
      photos: room.photos || ['', '']
    };
    setEditingRoom(roomWithPhotos);
    setIsEditing(true);
  };

  const handleAddNew = () => {
    setEditingRoom(initialRoomState);
    setIsEditing(true);
  };

  const handlePhotoChange = (index: number, url: string) => {
    setEditingRoom(prev => {
      if (!prev) return null;
      const newPhotos = [...(prev.photos || ['', ''])];
      newPhotos[index] = url;
      return { ...prev, photos: newPhotos };
    });
    setPreviewUrls(prev => {
      const newUrls = [...prev];
      newUrls[index] = url;
      return newUrls;
    });
  };

  const handleSave = async () => {
    if (!editingRoom) return;

    // Фильтруем пустые URL перед сохранением
    const filteredPhotos = editingRoom.photos?.filter(url => url.trim() !== '') || [];

    const roomToSave = {
      ...editingRoom,
      photos: filteredPhotos
    };

    try {
      const url = editingRoom._id 
        ? `/api/rooms/${editingRoom._id}` 
        : '/api/rooms';
      
      const method = editingRoom._id ? 'PUT' : 'POST';

      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(roomToSave),
      });

      const data = await response.json();

      if (data.success) {
        await fetchRooms();
        setIsEditing(false);
        setEditingRoom(null);
      }
    } catch (error) {
      console.error('Error saving room:', error);
    }
  };

  const handleDelete = async (roomId: string) => {
    if (!window.confirm('Вы уверены, что хотите удалить этот кабинет?')) {
      return;
    }

    try {
      const response = await fetch(`/api/rooms/${roomId}`, {
        method: 'DELETE',
      });

      const data = await response.json();

      if (data.success) {
        await fetchRooms();
      }
    } catch (error) {
      console.error('Error deleting room:', error);
    }
  };

  return (
    <div className="management-container">
      <div className="management-header">
        <h2>Управление кабинетами</h2>
        {!isEditing && (
          <button onClick={handleAddNew} className="add-button">
            Добавить кабинет
          </button>
        )}
      </div>
      
      <div className="management-content">
        {isEditing ? (
          <div className="edit-form">
            <input
              type="text"
              placeholder="Название кабинета"
              value={editingRoom?.name || ''}
              onChange={e => setEditingRoom(prev => prev ? {...prev, name: e.target.value} : null)}
              className="input"
            />
            
            <div className="price-inputs">
              <input
                type="number"
                placeholder="Цена для группы"
                value={editingRoom?.groupPrice || ''}
                onChange={e => setEditingRoom(prev => prev ? {...prev, groupPrice: Number(e.target.value)} : null)}
                className="input"
              />
              
              <input
                type="number"
                placeholder="Цена для индивидуального"
                value={editingRoom?.individualPrice || ''}
                onChange={e => setEditingRoom(prev => prev ? {...prev, individualPrice: Number(e.target.value)} : null)}
                className="input"
              />
            </div>

            <div className="photo-inputs">
              {[0, 1].map((index) => (
                <div key={index} className="photo-input-container">
                  <input
                    type="url"
                    placeholder={`URL ${index + 1}-й фотографии (необязательно)`}
                    value={editingRoom?.photos?.[index] || ''}
                    onChange={e => handlePhotoChange(index, e.target.value)}
                    className="input"
                  />
                  {previewUrls[index] && (
                    <div className="photo-preview">
                      <img
                        src={previewUrls[index]}
                        alt={`Preview ${index + 1}`}
                        onError={(e) => {
                          const target = e.target as HTMLImageElement;
                          target.src = '/api/placeholder/200/150';
                        }}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>

            <textarea
              placeholder="Описание кабинета (необязательно)"
              value={editingRoom?.description || ''}
              onChange={e => setEditingRoom(prev => prev ? {...prev, description: e.target.value} : null)}
              className="textarea"
            />

            <div className="button-group">
              <button
                onClick={() => {
                  setIsEditing(false);
                  setEditingRoom(null);
                }}
                className="cancel-button"
              >
                Отмена
              </button>
              
              <button onClick={handleSave} className="save-button">
                Сохранить
              </button>
            </div>
          </div>
        ) : (
          <div className="rooms-list">
            {rooms.map(room => (
              <div key={room._id} className="room-card">
                <div className="room-photos">
                  <div className="photo-container" style={{ backgroundColor: room.photos?.[0] ? 'transparent' : '#969696' }}>
                    {room.photos?.[0] && <img src={room.photos[0]} alt="Первое фото" />}
                  </div>
                  <div className="photo-container" style={{ backgroundColor: room.photos?.[1] ? 'transparent' : '#969696' }}>
                    {room.photos?.[1] && <img src={room.photos[1]} alt="Второе фото" />}
                  </div>
                </div>
                <div className="room-content">
                  <h3>{room.name}</h3>
                  <p className="price-info">
                    {room.groupPrice} руб/ч – группа
                    <br />{room.individualPrice} руб/ч – индивид
                  </p>
                  {room.description && (
                    <>
                      <h4>Описание:</h4>
                      <p className="description">{room.description}</p>
                    </>
                  )}
                </div>
                <div className="room-actions">
                  <button onClick={() => handleEditClick(room)} className="edit-button">
                    Редактировать
                  </button>
                  <button onClick={() => room._id && handleDelete(room._id)} className="delete-button">
                    Удалить
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default RoomManagementScreen;