import React, { useState } from 'react';
import { User } from '../types';
import * as phoneUtils from '../utils/phoneUtils';

interface LoginScreenProps {
  onLogin: (user: User, phone: string) => void;
  onRegistrationNeeded: (phone: string) => void;
  initialPhone: string;
}

const LoginScreen: React.FC<LoginScreenProps> = ({ 
  onLogin, 
  onRegistrationNeeded, 
  initialPhone 
}) => {
  const [phone, setPhone] = useState<string>(initialPhone);

  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formatted = phoneUtils.formatPhoneNumber(e.target.value);
    setPhone(formatted);
  };

  const handlePhoneSubmit = async () => {
    const normalizedPhone = phoneUtils.normalizePhone(phone);
    
    try {
      // Ну бро ну тут только менять https://centerbryansk.ru постоянно
      const response = await fetch('/api/auth', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phone: normalizedPhone }),
      });

      const data = await response.json();

      if (data.success) {
        localStorage.setItem('userPhone', normalizedPhone);
        onLogin(data.user, phone);
      } else if (data.isNewUser) {
        onRegistrationNeeded(phone);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="auth-card">
      <h1>Вход</h1>
      <div className="input-group">
        <label>Телефон</label>
        <input
          type="tel"
          value={phone}
          onChange={handlePhoneChange}
          placeholder="+7 (800) 555-35-35"
          maxLength={18}
        />
      </div>
      <button 
        onClick={handlePhoneSubmit}
        disabled={phone.length < 18}
      >
        Продолжить
      </button>
    </div>
  );
};

export default LoginScreen;