import React, { useState, useEffect } from 'react';
import { User } from '../types';
import RoomManagementScreen from './RoomManagementScreen';
import AutomaticBookingScreen from './AutomaticBookingScreen';
import BookingRequestsScreen from './BookingRequestsScreen';
import AllBookingsScreen from './AllBookingsScreen';
import '../styles/AdminScreen.scss';
import EarningsDisplay from './molecules/EarningsDisplay';

import excelIcon from '../icons/excel-icon.svg';
import roomsPreview from '../icons/rooms-preview.png';
import controlPreview from '../icons/control-preview.png';
import autoPreview from '../icons/auto-preview.png';
import allBookingsPreview from '../icons/all-bookings-preview.png';

interface AdminScreenProps {
  user: User;
  onLogout: () => void;
}

const AdminScreen: React.FC<AdminScreenProps> = ({ user, onLogout }) => {
  const [currentScreen, setCurrentScreen] = useState<'main' | 'rooms' | 'schedule' | 'access' | 'all' | 'automatic'>('main');
  const [isExpanded, setIsExpanded] = useState(false);

  const handleExportBookings = async () => {
    try {
      const response = await fetch('/api/export-bookings');
      if (!response.ok) throw new Error('Export failed');
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'bookings.xlsx';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error('Error downloading bookings:', error);
    }
  };

  useEffect(() => {
    if (currentScreen !== 'main') {
      setTimeout(() => setIsExpanded(true), 50);
    } else {
      setIsExpanded(false);
    }
  }, [currentScreen]);

  const handleBackToMain = () => {
    setIsExpanded(false);
    setTimeout(() => setCurrentScreen('main'), 300);
  };

  const renderMainScreen = () => (
    <div className="admin-grid">
      <button className="admin-button2 download-button" onClick={handleExportBookings}>
        <img src={excelIcon} alt="Excel" className="excel-icon" />
        Скачать
      </button>
      
      <button className="admin-button2 logout-button" onClick={onLogout}>
        <span className="logouttext"> Выйти</span>
      </button>
      
      <button className="admin-button2 cards-button" onClick={() => setCurrentScreen('rooms')}>
        <img src={roomsPreview} alt="Залы" className="preview-image" />
        <span>Карточки</span>
      </button>
      
      <button className="admin-button2 control-button" onClick={() => setCurrentScreen('access')}>
        <img src={controlPreview} alt="Контроль броней" className="preview-image" />
        <span>Контроль</span>
      </button>
      
      <button className="admin-button2 auto-button" onClick={() => setCurrentScreen('automatic')}>
        <img src={autoPreview} alt="Автоматические брони" className="preview-image" />
        <span>Автоброни</span>
      </button>
      
      <button className="admin-button2 all-bookings-button" onClick={() => setCurrentScreen('all')}>
        <img src={allBookingsPreview} alt="Все брони" className="preview-image" />
        <span>Все брони</span>
      </button>
    </div>
  );

  const renderContent = () => {
    switch (currentScreen) {
      case 'rooms':
        return <RoomManagementScreen />;
      case 'access':
        return <BookingRequestsScreen />;
      case 'automatic':
        return <AutomaticBookingScreen />;
      case 'all':
        return <AllBookingsScreen />;
      default:
        return renderMainScreen();
    }
  };

  return (
    <div className={`auth-card ${isExpanded ? 'expanded' : ''}`}>
      {currentScreen !== 'main' && (
        <div className="admin-header">
          <button className="admin-button back" onClick={handleBackToMain}>
            Вернуться назад
          </button>
        </div>
      )}
      {renderContent()}
      <EarningsDisplay />
    </div>
  );
};

export default AdminScreen;