// ProfileScreen.tsx
import React, { useState, useEffect } from 'react';
import { User, Room } from '../types';
import '../styles/ProfileScreen.scss';
import BookingScreen from './BookingScreen';
import UserBookings from './UserBookings';

interface ProfileScreenProps {
  user: User;
  onLogout: () => void;
}

const ProfileScreen: React.FC<ProfileScreenProps> = ({ user, onLogout }) => {
  const [rooms, setRooms] = useState<Room[]>([]);
  const [selectedRoom, setSelectedRoom] = useState<Room | null>(null);
  const [showMyBookings, setShowMyBookings] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchRooms();
  }, []);

  const fetchRooms = async () => {
    try {
      setIsLoading(true);
      const response = await fetch('/api/rooms');
      const data = await response.json();
      if (data.success) {
        setRooms(data.rooms);
      }
    } catch (error) {
      console.error('Error fetching rooms:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleRoomClick = (room: Room) => {
    setSelectedRoom(room);
  };

  const handleBackToRooms = () => {
    setSelectedRoom(null);
    setShowMyBookings(false);
  };

  const handleShowMyBookings = () => {
    setSelectedRoom(null);  // Очищаем выбранную комнату
    setShowMyBookings(true);
  };

  // Если выбрана комната, показываем экран бронирования
  if (selectedRoom) {
    return (
      <BookingScreen
        room={selectedRoom}
        user={user}
        onBack={handleBackToRooms}
        showMyBookings={handleShowMyBookings}
      />
    );
  }

  // Показываем загрузку, пока данные загружаются
  if (isLoading) {
    return (
      <div className="profile-container">
        <div className="loading">Загрузка...</div>
      </div>
    );
  }

  return (
    <div className="profile-container">
      <div className="profile-header">
        <div className="tabs">
          <button 
            className={`tab-button ${!showMyBookings ? 'active' : ''}`}
            onClick={() => setShowMyBookings(false)}
          >
            Все помещения
          </button>
          <button 
            className={`tab-button ${showMyBookings ? 'active' : ''}`}
            onClick={() => setShowMyBookings(true)}
          >
            Мои записи
          </button>
        </div>
        <button 
          className="logout-button"
          onClick={onLogout}
        >
          Выйти
        </button>
      </div>

      <div className="profile-content">
        {showMyBookings ? (
          <UserBookings userId={user._id} />
        ) : (
          <div className="rooms-grid">
            {rooms.length === 0 ? (
              <div className="no-rooms">
                <p>Нет доступных помещений</p>
              </div>
            ) : (
              rooms.map(room => (
                <div 
                  key={room._id} 
                  className="room-card"
                  onClick={() => handleRoomClick(room)}
                >
                  <div className="room-photo">
                    {room.photos?.[0] ? (
                      <img 
                        src={room.photos[0]} 
                        alt={room.name}
                        onError={(e) => {
                          const target = e.target as HTMLImageElement;
                          target.src = '/api/placeholder/400/300';
                        }}
                      />
                    ) : (
                      <div className="placeholder-photo" />
                    )}
                  </div>
                  <div className="room-info">
                    <h2>{room.name}</h2>
                    <div className="price-info">
                      <p className="price group-price">
                        <span className="label">Группа:</span>
                        <span className="amount">{room.groupPrice} ₽/ч</span>
                      </p>
                      <p className="price individual-price">
                        <span className="label">Инд.:</span>
                        <span className="amount">{room.individualPrice} ₽/ч</span>
                      </p>
                    </div>
                    {room.description && (
                      <p className="description">{room.description}</p>
                    )}
                  </div>
                </div>
              ))
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileScreen;