import React, { useState, useEffect } from 'react';
import './styles.scss';
import { User } from './types';
import LoginScreen from './components/LoginScreen';
import RegistrationScreen from './components/RegistrationScreen';
import ProfileScreen from './components/ProfileScreen';
import * as phoneUtils from './utils/phoneUtils';
import AdminScreen from './components/AdminScreen';

const App: React.FC = () => {
  const [currentPhone, setCurrentPhone] = useState<string>('+7 ');
  const [step, setStep] = useState<'phone' | 'name' | 'welcome'>('phone');
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const savedPhone = localStorage.getItem('userPhone');
    if (savedPhone) {
      handleAutoAuth(savedPhone);
    } else {
      setIsLoading(false);
    }
  }, []);

  const handleAutoAuth = async (savedPhone: string) => {
    try {
      // Ну бро ну тут только менять 1http://localhost:3001/api/auth постоянно
      const response = await fetch('/api/auth', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ phone: savedPhone }),
      });

      const data = await response.json();

      if (data.success) {
        setUser(data.user);
        setStep('welcome');
        setCurrentPhone(phoneUtils.formatPhoneForDisplay(savedPhone));
      }
    } catch (error) {
      console.error('Auto auth error:', error);
      localStorage.removeItem('userPhone');
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogin = (user: User, phone: string) => {
    setUser(user);
    setCurrentPhone(phone);
    setStep('welcome');
  };

  const handleRegistrationNeeded = (phone: string) => {
    setCurrentPhone(phone);
    setStep('name');
  };

  const handleRegister = (user: User) => {
    setUser(user);
    setStep('welcome');
  };

  const handleLogout = () => {
    localStorage.removeItem('userPhone');
    setUser(null);
    setStep('phone');
    setCurrentPhone('+7 ');
  };

  if (isLoading) {
    return (
      <div className="container">
        <div className="auth-card">
          <h1>Загрузка...</h1>
        </div>
      </div>
    );
  }

  const renderUserScreen = () => {
    if (!user) return null;
    
    if (user.role === 'admin') {
      return (
        <AdminScreen
          user={user}
          onLogout={handleLogout}
        />
      );
    }
    
    return (
      <ProfileScreen
        user={user}
        onLogout={handleLogout}
      />
    );
  };

  return (
    <div className="container">
      {step === 'phone' && (
        <LoginScreen 
          onLogin={handleLogin}
          onRegistrationNeeded={handleRegistrationNeeded}
          initialPhone={currentPhone}
        />
      )}

      {step === 'name' && (
        <RegistrationScreen
          phone={currentPhone}
          onRegister={handleRegister}
        />
      )}

      {step === 'welcome' && user && renderUserScreen()}
    </div>
  );
};

export default App;